import * as React from 'react';
import { createContext, useContext, useState } from 'react';
import { z } from 'zod';

import { IneligibleReasons, payloadType, Steps } from '../components/meetUs/types/types';
import useModal from '../hooks/useModal';
import { checkURLHealth } from '../utils/URLHealthChecker';

interface MeetUsContextData {
    step: '' | Steps;
    calendlyLink: string;
    setCalendlyLink: (link: string) => void;
    setStep: (step: Steps) => void;
    getCalendlyLink?: (payload?: payloadType | null) => Promise<string | [IneligibleReasons, IneligibleReasons?]>;
    setPayload?: (prevState: payloadType) => void;
    payload: payloadType;
    openModal: () => void;
    closeModal: () => void;
    isModalOpen: boolean;
    serviceOfInterest: string;
    setServiceOfInterest: (serviceOfInterest: string) => void;
}

export const MeetUsContext = createContext<MeetUsContextData>({
    step: '',
    calendlyLink: '',
    setStep: () => {},
    setCalendlyLink: () => {},
    getCalendlyLink: undefined,
    payload: {} as payloadType,
    openModal: () => {},
    closeModal: () => {},
    isModalOpen: false,
    serviceOfInterest: '',
    setServiceOfInterest: () => {},
});

const CalendlyLinkIneligibleErrorSchema = z.object({
    statusCode: z.literal(400),
    error: z.literal('Bad Request'),
    message: z
        .nativeEnum(IneligibleReasons)
        .array()
        .min(1)
        .max(2)
        .transform(data => data as [IneligibleReasons, IneligibleReasons?]),
});

const CalendlyLinkEligibleResponseSchema = z.object({
    url: z.string().url(),
});

const MeetUsContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [payload, setPayload] = useState({} as payloadType);
    const [step, setStep] = useState('' as Steps);
    const [hasSentEvent, setHasSentEvent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [calendlyLink, setCalendlyLink] = useState('');
    const [serviceOfInterest, setServiceOfInterest] = useState('');

    const formatServiceOfInterest = data => {
        if (data === 'crea') {
            return ['Pour la création de mon entreprise'];
        }
        if (data === 'compta') {
            return ['Pour la comptabilité de mon entreprise'];
        }
        if (data === 'both') {
            return ['Pour la comptabilité de mon entreprise', 'Pour la création de mon entreprise'];
        }
        return data;
    };

    async function getCalendlyLink(data: payloadType) {
        const transformCalendlyUrl = (url: string): string => {
            if (url.includes('calendly.com/thomas-fournier')) {
                return url.replace('thomas-fournier', 'thomas_fournier');
            }
            return url;
        };

        if (!loading) {
            try {
                setLoading(true);
                const contact = data || payload;
                delete contact.fetch;
                delete contact.client;
                contact.serviceOfInterest = formatServiceOfInterest(contact.serviceOfInterest);

                const response = await fetch('/.netlify/functions/calendly', {
                    method: 'POST',
                    body: JSON.stringify(contact),
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    },
                    redirect: 'follow' as RequestRedirect,
                });

                if (response.ok) {
                    const { url } = CalendlyLinkEligibleResponseSchema.parse(await response.json());

                    const transformedUrl = transformCalendlyUrl(url);

                    await checkURLHealth(`${transformedUrl}/20-minutes`, 'Calendly link is not accessible');

                    return transformedUrl;
                }

                if (response.status === 400) {
                    const { message } = CalendlyLinkIneligibleErrorSchema.parse(await response.json());
                    return message;
                }

                throw new Error('Something went wrong');
            } finally {
                setLoading(false);
            }
        }
    }

    const { isModalOpen, closeModal, openModal } = useModal();

    return (
        <MeetUsContext.Provider
            value={{
                step,
                setStep,
                calendlyLink,
                setCalendlyLink,
                getCalendlyLink,
                setPayload,
                payload,
                hasSentEvent,
                setHasSentEvent,
                openModal,
                isModalOpen,
                closeModal,
                serviceOfInterest,
                setServiceOfInterest,
            }}
        >
            {children}
        </MeetUsContext.Provider>
    );
};

export default MeetUsContextProvider;

export function useMeetUsContext() {
    return useContext(MeetUsContext);
}
