import * as Sentry from '@sentry/browser';

/**
 * Checks if a URL is accessible by making a GET request
 * @param url - The URL to check
 * @returns Promise<boolean> - Returns true if URL is accessible, false otherwise
 */
export async function checkURLHealth(url: string, errorName?: string): Promise<boolean> {
    try {
        const response = await fetch(`/.netlify/functions/check-url?url=${encodeURIComponent(url)}`);

        const data = await response.json();

        if (!data.isHealthy) {
            throw new Error(data.error || 'URL is not healthy');
        }

        return true;
    } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';

        Sentry.captureMessage(errorName || 'URL health check failed', {
            level: 'fatal',
            extra: {
                error,
                url,
                timestamp: new Date().toISOString(),
            },
        });

        console.error('URL health check failed:', { url, error: errorMessage });
        return false;
    }
}
